import React from 'react'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import ISlide from '../interfaces/ISlide'
import { makeStyles } from '@material-ui/styles'
import {IconButton, Theme} from '@material-ui/core'
import clsx from 'clsx'
import './slider.css'

const useStyles = makeStyles((theme: Theme) => {
    return {
        sliderRoot:{
            paddingTop: 50, 
            paddingBottom: 0,
            paddingRight: 250,
            paddingLeft: 250,        
        },
        slideImage:{
            maxHeight: '650px'
        },
        
    }
})


interface IPropsSlider{
    slides: ISlide[]
    options: any
}

const Slider: React.FC<IPropsSlider> = (props) => {
    const classes = useStyles()
    const onSlideChange = ()=>{
    }

    const { options} = props
    const list = props.slides.map((slide, i)=>{
        return<div key={slide.src + i}>
                <img className={classes.slideImage} src={`/images/${slide.src}`} alt={slide.title}/>
              </div> 
    })

    return (
        <div>
            <Carousel {...options}>
                {list }
            </Carousel>
        </div>
    )
}

export default Slider
